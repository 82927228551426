<template>
  <div>
    <b-row>
      <b-col
        md="2"
        sm="4"
        class="my-1"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-md-left mr-50">Show</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="md"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="2"
        class="my-1"
      />
      <b-col
        md="4"
        class="my-1"
      >
        <b-form-group
          label="Search"
          label-cols-md="3"
          label-align-md="right"
          label-size="md"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <div class="scl-button-alignment">
          <b-button
            variant="outline-primary"
            class="mr-2"
            @click="refreshGrid"
          >
            <span class="align-middle">Refresh</span>
            <feather-icon
              icon="RefreshCwIcon"
              class="ml-50"
            />
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="rowCount"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="paymentsList.payments"
          :fields="fields"
          head-variant="primary"
          table-variant="light-primary"
          :sort-compare="sortCompare"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          @row-clicked="showRowDetails"
        >
          <template v-slot:row-details="row">
            <PaymentsSubDetails :row-data="row.item" />
          </template>

          <template #head(sageid)="data">
            <div class="text-left">
              {{ data.label.toUpperCase() }}
            </div>
          </template>
          <template #cell(sageid)="data">
            <div class="text-left">
              {{ data.item.operative[0].operativedetails[0].sage_id }}
            </div>
          </template>

          <template #head(name)="data">
            <div class="text-left">
              {{ data.label.toUpperCase() }}
            </div>
          </template>
          <template
            v-slot:cell(name)="data"
            data-html="true"
          >
            <router-link
              :to="{ path: '/operativeedit?operativeid=' + data.item.ent_id }"
            >
              {{ data.item.operative[0].firstname }} {{ data.item.operative[0].lastname }}
            </router-link>
          </template>

          <template #cell(operative)="data">
            <paymentOperativeIssueIcons :data="data" />
          </template>

          <template #head(days)="data">
            <div class="text-right">
              {{ data.label.toUpperCase() }}
            </div>
          </template>
          <template #cell(days)="data">
            <div class="text-right p-0">
              {{ formatDecimalPlaces(data.value) }}
            </div>
          </template>

          <template #head(dayrate)="data">
            <div class="text-right">
              {{ data.label.toUpperCase() }}
            </div>
          </template>
          <template #cell(dayrate)="data">
            <div class="text-right">
              {{ formatCurrency(data.value) }}
            </div>
          </template>

          <template #head(extras)="data">
            <div class="text-right">
              {{ data.label.toUpperCase() }}
            </div>
          </template>
          <template #cell(extras)="data">
            <div class="text-right">
              {{ formatCurrency(data.value) }}
            </div>
          </template>

          <template #head(invtotal)="data">
            <div class="text-right">
              {{ data.label.toUpperCase() }}
            </div>
          </template>
          <template #cell(invtotal)="data">
            <div class="text-right">
              {{ formatCurrency(data.value) }}
            </div>
          </template>

          <template #head(charges)="data">
            <div class="text-right">
              {{ data.label.toUpperCase() }}
            </div>
          </template>
          <template #cell(charges)="data">
            <div class="text-right">
              {{ formatCurrency(data.value) }}
            </div>
          </template>

          <template #head(fees)="data">
            <div class="text-right">
              {{ data.label.toUpperCase() }}
            </div>
          </template>
          <template #cell(fees)="data">
            <div class="text-right">
              {{ formatCurrency(data.value) }}
            </div>
          </template>

          <template #head(total)="data">
            <div class="text-right">
              {{ data.label.toUpperCase() }}
            </div>
          </template>
          <template #cell(total)="data">
            <div class="text-right">
              {{ formatCurrency(data.value) }}
            </div>
          </template>

          <template #cell(status)="data">
            <paymentStatusComponent :status="data.value" />
          </template>

          <template
            v-slot:cell(actions)="data"
            data-html="true"
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                :disabled="data.item.status >= 7"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="data.item.status == '6'"
                  @click="updateHoldStatus(data)"
                >
                  <feather-icon
                    icon="PlayIcon"
                    class="mr-50"
                  />
                  <span>Unhold</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.status == '5'"
                  @click="updateHoldStatus(data)"
                >
                  <feather-icon
                    icon="PlayIcon"
                    class="mr-50"
                  />
                  <span>Retry</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-else-if="data.item.status < '7' && data.item.status > 3"
                  @click="updateHoldStatus(data)"
                >
                  <feather-icon
                    icon="PauseIcon"
                    class="mr-50"
                  />
                  <span>Hold</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="rowCount"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BDropdown, BDropdownItem, VBModal,
} from 'bootstrap-vue'
import paymentStatusComponent from '@/components/payments/ui/PaymentStatusComponent.vue'
import paymentOperativeIssueIcons from '@/components/payments/ui/PaymentOperativeIssueIcons.vue'
import PaymentsSubDetails from '@/components/payments/ui/PaymentSubDetails.vue'
import { mapGetters } from 'vuex'
import jwt from '@/auth/jwt/sclUseJwt'
import swal from 'sweetalert2'

export default {
  name: 'SageExportTable',
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    paymentStatusComponent,
    paymentOperativeIssueIcons,
    PaymentsSubDetails,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    batchId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      perPage: 100,
      pageOptions: [3, 5, 10, 50, 100],
      rowCount: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'sageid', label: 'Id', sortable: true },
        { key: 'name', label: 'NAME', sortable: true },
        { key: 'operative', label: 'ISSUES', sortable: true },
        { key: 'days', label: 'DAYS', sortable: true },
        { key: 'dayrate', label: 'DAY RATE', sortable: true },
        { key: 'extras', label: 'EXTRAS', sortable: true },
        { key: 'invtotal', label: 'INVOICE TOTAL', sortable: true },
        { key: 'charges', label: 'CHARGES', sortable: true },
        { key: 'fees', label: 'SCL FEE', sortable: true },
        // { key: 'pay', label: 'PAY', sortable: true },
        // { key: 'tax', label: 'TAX', sortable: true },
        { key: 'total', label: 'GROSS', sortable: true },
        { key: 'status', label: 'STATUS', sortable: true },
        { key: 'actions', label: 'ACTIONS' },
      ],
      rowDetailsFields: [
        { key: 'paytype', label: 'TYPE', sortable: false },
        { key: 'days', label: 'DAYS', sortable: false },
        { key: 'dayrate', label: 'RATE', sortable: false },
        { key: 'charge', label: 'AMOUNT', sortable: false },
      ],
    }
  },
  computed: {
    ...mapGetters({ paymentsList: 'app/getPaymentsForCurrentClient' }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.refreshGrid()
  },
  methods: {
    sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      let a = aRow[key] // or use Lodash `_.get()`
      let b = bRow[key]
      this.sortDesc = sortDesc
      this.formatter = formatter

      if (key === 'sageid') {
        a = aRow.operative[0].operativedetails[0].sage_id
        b = bRow.operative[0].operativedetails[0].sage_id
      } else if (key === 'name') {
        a = aRow.operative[0].firstname + aRow.operative[0].lastname
        b = bRow.operative[0].firstname + bRow.operative[0].lastname
      } else if (key === 'operative') {
        a = aRow.operative[0].operativeproblems.errorcount + aRow.operative[0].operativeproblems.warningcount
        b = bRow.operative[0].operativeproblems.errorcount + bRow.operative[0].operativeproblems.warningcount
      }

      if (
        (typeof a === 'number' && typeof b === 'number')
         || (a instanceof Date && b instanceof Date)
      ) {
        // If both compared fields are native numbers or both are native dates
        if (a < b) return -1
        if (a > b) return 1
        return 0
      }
      // Otherwise stringify the field data and use String.prototype.localeCompare
      return this.toAString(a).localeCompare(this.toAString(b), compareLocale, compareOptions)
    },

    // Helper function to stringify the values of an Object
    toAString(value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      }
      if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map(key => toString(value[key]))
          .join(' ')
      }

      return String(value)
    },
    formatDecimalPlaces(value) {
      if (typeof value === 'string') return Number(value).toFixed(2)

      return value.toFixed(2)
    },
    formatCurrency(value) {
      return `£${this.formatDecimalPlaces(value)}`
    },
    showMessage(message, inError) {
      swal.fire({
        title: inError ? 'Error' : 'Success!',
        html: message,
        icon: inError ? 'error' : 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    updateHoldStatus(row) {
      jwt.axiosIns.get(`/togglesage/${row.item.id}/`)
        .then(response => {
          if (response.data.result === 'success') {
            this.$store.commit('app/updatePaymentStatus',
              {
                payment_id: row.item.id,
                status: response.data.status,
              })
            // row.item.status = response.data.status
          } else {
            this.showMessage(response.data.message, true)
          }
        })
        .catch(e => {
          console.error(e)
        })
    },
    refreshGrid() {
      this.$store.commit('app/resetPaymentsForCurrentClient')
      jwt.axiosIns.get(`/reviewfile/${this.batchId}/`)
        .then(response => {
          this.$store.commit('app/getPaymentsForCurrentClient', JSON.parse(JSON.stringify(response.data)))
          this.rowCount = this.paymentsList.payments.length
        })
        .catch(e => {
          console.error(e)
        })
    },
    async showRowDetails(row) {
      // Row clicked on, but if we don't have a breakdown prevent row detail show
      // /* eslint no-underscore-dangle: ["error", { "allow": ["row_", "showDetails"] }] didn't want to spend the time working out this rule, went nuclear below by disabling altogether*/
      /* eslint-disable */
      if (row._showDetails) {
        this.$set(row, '_showDetails', false)
      } else {
        this.$set(row, '_showDetails', true)
      }
      /* eslint-enable */
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style scoped>
.scl-button-alignment {
  margin-top: 0.9rem;
  float: right;
}
</style>
