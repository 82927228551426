<template>
  <div>
    <b-table-simple
      :borderless="true"
      :small="true"
    >
      <b-tbody>
        <b-tr>
          <b-td class="scl-total-heading text-center">
            Operatives
          </b-td>
          <b-td class="scl-total-heading text-center">
            Net from client
          </b-td>
          <b-td class="scl-total-heading text-center">
            Client fee
          </b-td>
          <b-td class="scl-total-heading text-center">
            Invoice Total Ex VAT
          </b-td>
          <b-td class="scl-total-heading text-center">
            Operative Fees
          </b-td>
          <b-td class="scl-total-heading text-center">
            Operative Charges
          </b-td>
          <b-td class="scl-total-heading text-center">
            CIS Tax
          </b-td>
          <b-td class="scl-total-heading text-center">
            Operative's VAT
          </b-td>
          <b-td class="scl-total-heading text-center">
            BACS payment
          </b-td>
          <b-td
            rowspan="2"
            class="scl-total-heading text-center"
          >
            <b-button
              v-b-modal.modal-xs
              type="submit"
              variant="primary"
              block
              class="scl-button-size-settings"
            >
              Send To Sage
            </b-button>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td class="scl-total-data text-center">
            {{ paymentInfo.itemcount }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.totalprefee }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.clientfee }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.clientpayment }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.opfees }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.opcharges }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.totaltax }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.opvat }}
          </b-td>
          <b-td class="scl-total-data text-center">
            £{{ paymentInfo.totalpay }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-modal
      id="modal-xs"
      cancel-variant="secondary"
      cancel-title="Cancel"
      ok-title="Send To Sage"
      centered
      hide-header-close
      size="xs"
      title="Sage submission"
      @ok="submitToSageCheck"
    >
      <b-card-text>
        <p>
          You are about to send the payments to Sage, have you confirmed the values are correct for:
        </p>
      </b-card-text>
      <b-card-text>
        <b-badge
          class="profile-badge mb-1"
          variant="light-primary"
        >
          {{ clientName }}
        </b-badge>
      </b-card-text>
      <b-card-text>
        <p>
          Week: {{ paymentInfo.weekno }}
        </p>
      </b-card-text>
      <b-card-text>
        <p>
          Gross from client: £{{ paymentInfo.clientpayment }}
        </p>
      </b-card-text>
      <!-- <b-card-text>
        <p>
          Service fee: £{{ paymentInfo.fees }}
        </p>
      </b-card-text> -->
      <b-card-text>
        <p>
          BACS Payment: £{{ paymentInfo.totalpay }}
        </p>
      </b-card-text>
    </b-modal>

    <b-modal
      id="sage-error-modal"
      ref="sage-error-modal"
      title="Error"
      ok-only
      ok-title="Close"
      ok-variant="danger"
      centered
      hide-header-close
      modal-class="modal-danger"
    >
      <b-card-text>
        There are Operatives with errors, payments cannot be sent to Sage.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BTableSimple, BTr, BTd, BTbody, BButton, VBModal, BModal, BBadge, BCardText,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'

export default {
  name: 'SageExportTotals',
  components: {
    BTableSimple,
    BTr,
    BTd,
    BTbody,
    BButton,
    BModal,
    BBadge,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
  },
  computed: {
    ...mapGetters({ paymentInfo: 'app/getPaymentsForCurrentClient' }),
    clientName() {
      if (this.dataIsValid === false) return ''

      return this.paymentInfo.client[0].name
    },
    dataIsValid() {
      if (this.paymentInfo === undefined || this.paymentInfo.client === undefined) return false
      if (this.paymentInfo.client.length === 0) return false

      return true
    },
  },
  methods: {
    checkForErrorsOrWarnings() {
      let result = 0
      for (let iPayment = 0; iPayment < this.paymentInfo.payments.length; iPayment += 1) {
        // Skip ones on Hold or with a greater status
        /* eslint-disable */
        // Status on Sage hold or hasn't been paid or has alreayd been flagged for Sage, skip it
        if (this.paymentInfo.payments[iPayment].status === '6' ||  this.paymentInfo.payments[iPayment].status < '4' || this.paymentInfo.payments[iPayment].status >= '7') {
          continue
        }
        /* eslint-enable */
        if (this.paymentInfo.payments[iPayment].operative[0].operativeproblems.warningcount > 0) {
          result = 1 // Found a warning
        }

        if (this.paymentInfo.payments[iPayment].operative[0].operativeproblems.errorcount > 0) {
          result = -1 // Found an error, so break out
          break
        }
      }
      return result
    },
    showSageResult(response) {
      if (response.data.result === 'success') {
        swal.fire({
          title: 'Success!',
          text: 'Payments submitted to Sage',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        swal.fire({
          title: 'Error',
          text: 'Payments upload failed',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    displayErrorMessage() {
      this.$refs['sage-error-modal'].show()
    },
    displayWarningMessage() {
      /* eslint-disable */
      this.$bvModal
        .msgBoxConfirm('There are Operatives with warnings. Do you still wish to send the payments to Sage?', {
          title: 'Warning',
          titleVariant: 'Warning',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Send To Sage',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-warning',
          modalClass: 'modal-warning',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          this.submitToSage()
          return value === true
        })
      /* eslint-enable */
    },
    async submitToSageCheck() {
      if (this.dataIsValid === false) return

      const result = this.checkForErrorsOrWarnings()
      if (result < 0) {
        this.displayErrorMessage()
        return
      }
      if (result > 0) {
        this.displayWarningMessage()
        return
      }

      this.submitToSage()
    },
    async submitToSage() {
      try {
        await this.$store.dispatch('app/submitToSage', { batchid: this.paymentInfo.id })
      } catch (err) {
        console.error('Error occurred when calling API: ', err.message)
        this.$swal({
          title: 'Error',
          text: err.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        throw err
      }
    },
  },
}
</script>

<style scoped>
.scl-total-heading {
  color: #B9B9C3;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
}

.scl-total-data {
  color: #5E5873;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}
</style>
