<template>
  <div>
    <h1
      class="mt-2 mb-2"
    >
      {{ pageHeading }}
    </h1>
    <b-card>
      <sageExportTotals />
    </b-card>
    <b-card>
      <sageExportTable :batch-id="this.$route.query.batchid" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import sageExportTotals from '@/components/sage/SageExportTotals.vue'
import sageExportTable from '@/components/sage/SageExportTable.vue'
import { mapGetters } from 'vuex'

export default {
  middleware: ['check-auth', 'auth'],
  components: {
    BCard,
    sageExportTable,
    sageExportTotals,
  },
  computed: {
    ...mapGetters({ paymentInfo: 'app/getPaymentsForCurrentClient' }),
    pageHeading() {
      if (this.paymentInfo === undefined || this.paymentInfo.client === undefined) return ''
      if (this.paymentInfo.client.length === 0) return ''

      return `${this.paymentInfo.client[0].name} - week ${this.paymentInfo.weekno}`
    },
  },
}
</script>

<style>

</style>
